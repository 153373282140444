@import './App.scss';

.order-wrapper__new {
  width: $width;
  border-radius: 10px;
  @include new_status_box;
  transition: background-color 500ms ease, color 1000ms ease;

  &.light {
    color: $dk-color;
    filter: contrast(120%);
  }
}

.order-wrapper__ready {
  width: $width;
  border-radius: 10px;
  color: $pm-text-color;

  @include ready_status_box;
  transition: background-color 500ms ease, color 1000ms ease;

  &.light {
    color: $dk-color;
    filter: contrast(120%);
  }
}

.order-wrapper__preparing {
  width: $width;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: $pm-text-color;
  @include preparing_status_box;
  transition: background-color 500ms ease, color 1000ms ease;

  &.light {
    color: $dk-color;
    filter: contrast(120%);
  }
}

.order-wrapper__completed {
  width: $width;
  border-radius: 10px;
  color: $pm-text-color;
  @include completed_status_box;
  transition: background-color 500ms ease, color 1000ms ease;

  &.light {
    color: $dk-color;
    filter: contrast(120%);
  }
}

.order-wrapper__printed {
  width: $width;
  border-radius: 10px;
  color: $pm-text-color;
  @include new_status_box;
  transition: background-color 500ms ease, color 1000ms ease;

  &.light {
    color: $dk-color;
    filter: contrast(120%);
  }
}

.order-wrapper {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
  letter-spacing: 1.3px;
  line-height: 1.3em;
  font-size: 18px;
  border-radius: 10px;
  background-color: #fff;

  .order {
    display: flex;
    flex-flow: column;
    padding: 10px;
    text-transform: capitalize;

    p {
      margin-bottom: 10px;
      font-size: 1em;
      font-weight: 800;
    }

    h2 {
      display: flex;
      // justify-content: center;
      align-items: center;
    }

    .note {
      color: red;
      clear: both;
      font-size: 1.4em;
      text-align: left;
      margin-top: 10px;
      text-decoration: underline;
    }

    .header {
      display: flex;
      flex-flow: column;
      padding: 6px 6px;
      border-radius: 10px;
      color: #707070;

      .top {
        display: flex;
        flex-flow: row;
        text-transform: uppercase;
        flex-direction: row;

        .left {
          text-align: left;
          width: 100%;
        }

        .right {
          display: flex;
          text-align: right;
          width: 50%;
          justify-content: center;
        }
      }

      .bottom {
        text-align: left;
        padding: 15px 0 15px 0;
      }
    }
  }
}
